import React, { useRef, useEffect } from "react";
import { SubHeading } from "../../components";
import ReactPlayer from 'react-player';
import { images } from "../../constants";
import "./Header.css";

const Header = () => {
  const playerRef = useRef(null);

  useEffect(() => {
    const handleFullScreenChange = () => {
      const videoElement = playerRef.current.getInternalPlayer();
      if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
        videoElement.style.objectFit = 'contain';
      } else {
        videoElement.style.objectFit = 'cover';
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
    };
  }, []);

  return (
    <div
      className="app__header app__bg app__wrapper section__padding background_custom_header"
      id="Willkommen"
    >
      <div className="background_overlay"></div>
      <div className="app__wrapper_info ">
        <SubHeading title="Willkommen liebe Gäste" icon="spoon" />
        <h1 className="app__header-h1">Al Pappagallo</h1>
        <p className="p__opensans">
          Unser Haus steht für höchste Qualität zu angemessenen Preisen. Sie essen bei uns in einem wunderschönen Ambiente mit freundlichem und aufmerksamen Personal, welches auf höchstem Niveau ausgebildet wurde und langjährige Erfahrung in der Gastronomie vorweist.
          Besonderen Wert legen wir auf erstklassige, frische Produkte, die in unserer „offenen Küche“ mit Liebe und Professionalität zubereitet werden.
          Lassen Sie sich von unseren italienischen Spezialitäten verzaubern
        </p>
        <p className="p__opensans" style={{ marginTop: '2rem' }}>
          Sehr geehrte Gäste, wir möchten Sie darauf hinweisen, dass Sie neben den Parkplätzen direkt vor unserem Restaurant auch die Parkmöglichkeiten auf der gegenüberliegenden Straßenseite des MVZ’s nutzen können. Sollten Sie feststellen, dass unsere hauseigenen Parkplätze bereits belegt sind, zögern Sie bitte nicht, Ihr Fahrzeug auf der anderen Seite abzustellen.
        </p>
        <p className="p__opensans">Wir freuen uns auf Ihren Besuch!</p>
        <p className="p__cormorant">Ihre Familie Ferrulli </p>
        <button
          type="button"
          className="custom__button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "#Speisekarte";
          }}
        >
          Speisen Entdecken
        </button>
      </div>

      <div className="app__wrapper_video">
        <ReactPlayer
          className="front"
          ref={playerRef}
          url={images.papagalo_video}
          width="100%"
          height="100%"
          playing={true}
          loop={true}
          muted={true}
          controls={true}
          playbackRate={1}
        />
      </div>
    </div>
  );
};

export default Header;
