import React, { useState ,useEffect} from "react";
import "./Popupform.css";
import { BsXCircleFill, BsCheckCircleFill } from "react-icons/bs";
function Popupform(props) {
	const [Name_pp, setName_pp] = useState(props.name);
	const [Date_pp, setDate_pp] = useState(props.date);
	const [Time_pp, setTime_pp] = useState(props.time);
	const [Phone_pp, setPhone_pp] = useState(props.phone);
	const [Email_pp, setEmail_pp] = useState(props.email);
	useEffect(() => { setName_pp(props.name)}, [props.name] )
	useEffect(() => { setDate_pp(props.date)}, [props.date] )
	useEffect(() => { setTime_pp(props.time)}, [props.time] )
	useEffect(() => { setPhone_pp(props.phone)}, [props.phone] )
	useEffect(() => { setEmail_pp(props.email)}, [props.email] )
	return props.trigger ? (
		<div className="popupcontainer">
			
			<div className="popupform form-hidden">
			{/* <h1>Bearbeiten</h1> */}
				<input
					type="text"
					placeholder="Name"
					value={Name_pp}
					onChange={(event) => {
						setName_pp(event.target.value);
					}}
				/>
				<input
					type="text"
					placeholder="Datum"
					value={Date_pp}
					onChange={(event) => {
						setDate_pp(event.target.value);
					}}
				/>
				<input
					type="text"
					placeholder="Zeitpunkt"
					value={Time_pp}
					onChange={(event) => {
						setTime_pp(event.target.value);
					}}
				/>
				<input
					type="text"
					placeholder="Telefonnummer"
					value={Phone_pp}
					onChange={(event) => {
						setPhone_pp(event.target.value);
					}}
				/>
				<input
					type="text"
					placeholder="Email"
					value={Email_pp}
					onChange={(event) => {
						setEmail_pp(event.target.value);
					}}
				/>

				<div className="buttons_wrapper">
					<BsCheckCircleFill className="add-btn" onClick={() => {}} />
					<div className="spliter" />
					<BsXCircleFill
						className="delete-btn"
						onClick={() => {
							props.setTrigger(false);
						}}
					/>
				</div>
			</div>
		</div>
	) : (
		""
	);
}

export default Popupform;
