import React from "react";
import { SubHeading } from "../../components";
import "./Michelin.css";
import { images } from "../../constants";

const Michelin = () => (
  <div
    className="app__header app__bg michelin_app__wrapper section__padding"
    id="Michelin"
  >
    <div className="michelin_wrapper_img">
      <img className="front" src={images.Michelin_front} alt="header img" />
    </div>
    <div className="app__wrapper_info ">
      <h1 className="app__header-h1">Michelin Guide 2024</h1>
      <p className="p__opensans">
      Liebe Gäste, wir sind überglücklich und stolz, Ihnen mitteilen zu dürfen, dass unser Restaurant erneut im Michelin Guide 2024 ausgezeichnet wurde! Diese herausragende Anerkennung ist das Ergebnis harter Arbeit, Leidenschaft und Hingabe unseres gesamten Teams. Wir möchten uns von Herzen bei Ihnen bedanken, liebe Gäste, für Ihre Treue und Unterstützung. Ohne Sie wäre dieser Erfolg nicht möglich gewesen</p>
    </div>
    {/* <div className="app__wrapper_img">
      <img className="front" src={images.headerImg} alt="header img" />
      <a href="#Weinkarte">
        <h1>Entdecken Sie die Weinkarte. </h1>
      </a>
    </div> */}

  </div>
);

export default Michelin;
