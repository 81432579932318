import React, {useEffect } from 'react';
import {
  BsArrowLeftShort,
  BsArrowRightShort,
} from "react-icons/bs";
import "./Galerie.css";
import 'lightbox2/dist/css/lightbox.min.css';
import lightbox from 'lightbox2';
import { images } from "../../constants";

const Weinkarte = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 400;
    } else {
      current.scrollLeft += 400;
    }
  };
  useEffect(() => {
    // Add data-lightbox attribute to all images
    document.querySelectorAll('.app__gallery-images_card img').forEach(image => {
      image.setAttribute('data-lightbox', 'gallery');
    });
  
    // Override Lightbox options
    lightbox.option({
      'showImageNumberLabel': false,
      'alwaysShowNavOnTouchDevices': true,
      'maxWidth': 1200, // Set maximum width of the lightbox
      'maxHeight': 1000, // Set maximum height of the lightbox
      'scrollZoom': true, // Enable scrolling within the lightbox
      'wrapAround': true, // Allow wrapping around to the first/last image
      'positionFromTop': 100,
      'resizeDuration':20,
      'fadeDuration':20,
      'imageFadeDuration':20
    });
  
  }, []);
  
  

  return (
    <div className="app__menu app__bg section__padding" id="Weinkarte">
      <div className="app__menu-content">
        <h1 className="headtext__cormorant">Weinkarte</h1>
      </div>

      <div className="app__menu-images">
        <div className="app__menu-images_container" ref={scrollRef}>
          {[
            images.weinkarte1,
            images.weinkarte2,
            images.weinkarte3,
            images.weinkarte4,
            images.weinkarte5,
            images.weinkarte6,
            images.weinkarte7,
            images.weinkarte8,
            images.weinkarte9,
            images.weinkarte10
          ].map((image, index) => (
            <a href={image} data-lightbox="weinkarte" key={`menu_image-${index + 1}`}>
            <div
              className="weinkarte_app__menu-images_card flex__center"
              
            >
              <img src={image} alt="menu_image" />
            </div>
            </a>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort
            className="gallery__arrow-icon-black"
            onClick={() => scroll("left")}
          />
          <BsArrowRightShort
            className="gallery__arrow-icon-black"
            onClick={() => scroll("right")}
          />
        </div>
      </div>
    </div>
  );
};
export default Weinkarte;
