import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineRestaurantMenu } from "react-icons/md";

import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".hamurgermenu");
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.pappagalo} alt="app logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__cormorant">
          <a href="#Willkommen">Willkommen</a>
        </li>
        <li className="p__cormorant">
          <a href="#Galerie">Galerie</a>
        </li>
        <li className="p__cormorant">
          <a href="#Speisekarte">Speisekarte</a>
        </li>
        <li className="p__cormorant">
          <a href="#Catering">Catering</a>
        </li>

        <li className="p__cormorant">
          <a href="#Anfahrt">Anfahrt</a>
        </li>

        {/* <li className="p__cormorant">
          <a href="#Karriere">Karriere</a>
        </li> */}
      </ul>
      <div className="app__navbar-login">
        <a href="#Kontakt" className="p__cormorant">
          Kontaktieren Sie Uns
        </a>
      </div>
      <div className="hamurgermenu">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
      </div>
      {toggleMenu && (
        <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
          <MdOutlineRestaurantMenu
            className="overlay__close"
            fontSize={27}
            onClick={() => setToggleMenu(false)}
          />
          <ul className="app__navbar-smallscreen_links">
            <li className="p__opensans">
              <a href="#Willkommen" onClick={() => setToggleMenu(false)}>
                Willkommen
              </a>
            </li>
            <li className="p__opensans">
              <a href="#Galerie" onClick={() => setToggleMenu(false)}>
                Galerie
              </a>
            </li>
            <li className="p__opensans">
              <a href="#Speisekarte" onClick={() => setToggleMenu(false)}>
              Speisekarte
              </a>
            </li>
            <li className="p__opensans">
              <a href="#Catering" onClick={() => setToggleMenu(false)}>
              Catering
              </a>
            </li>
            <li className="p__opensans">
              <a href="#Anfahrt" onClick={() => setToggleMenu(false)}>
                Anfahrt
              </a>
            </li>
            {/* <li className="p__opensans">
              <a href="#Karriere" onClick={() => setToggleMenu(false)}>
              Karriere
              </a>
            </li> */}
            <li className="p__opensans">
              <a href="#Kontakt" onClick={() => setToggleMenu(false)}>
                Kontakt
              </a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
