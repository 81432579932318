import React from "react";
import { SubHeading } from "../../components";
import "./Catering.css";
import { images } from "../../constants";

const Catering = () => (
  <div
    className="app__header app__bg app__wrapper section__padding"
    id="Catering"
  >
    <div className="app__wrapper_info ">
      <SubHeading title="Willkommen liebe Gäste" icon="spoon" />
      <h1 className="app__header-h1">Catering</h1>
      <p className="p__opensans">
      Wir bieten Ihnen einen exklusiven Catering-Service ab einer bestimmten Personenanzahl.
      Wir machen Ihnen gerne ein individuelles und auf Sie zugeschnittenes Angebot, so dass einer gelungenen Feier nichts im Wege steht!

      </p>
      <a href="#Kontakt">
          <h2 className="p__opensans">Kontaktieren Sie uns!</h2>
      </a>
      <p className="p__cormorant">Ihre Familie Ferrulli </p>
    </div>
    {/* <div className="app__wrapper_img">
      <img className="front" src={images.headerImg} alt="header img" />
      <a href="#Weinkarte">
        <h1>Entdecken Sie die Weinkarte. </h1>
      </a>
    </div> */}
     <div className="app__wrapper_img">
      <img className="front" src={images.catering} alt="header img" />
    </div>
  </div>
);

export default Catering;
