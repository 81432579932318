import React from 'react';
import './FindUs.css';
import { SubHeading } from '../../components';
import { images } from '../../constants';
const FindUs = () => {

  
  return (
    <div className="app__findUs app__bg app__wrapper section__padding" id="Anfahrt">
      <div className="app__wrapper_info">
    
          <h1 className="headtext__cormorant" style={{ marginBottom: '2rem' }}>Anfahrt</h1>
          <div className="app__wrapper-content">
                <p className="p__opensans">Ristorante Al Pappagallo – G. Ferrulli </p>
                <p className="p__opensans">Außer der Schleifmühle 73 </p>
                <p className="p__opensans"> 28203 Bremen  </p>
                <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Öffnungszeiten</p>
                <p className="p__opensans">Mo. - Fr.&nbsp;: von 12:00 bis 14:00 Uhr und von 18:00 bis 22:30 Uhr</p>
                <p className="p__opensans">Sa. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    : von 18:00 bis 22:30 Uhr</p>
                <p className="p__opensans">So. &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;     : Ruhetag</p>
          </div>
    
      </div>
      
      <div className="app__wrapper_img">
            <div className="gmap_canvas">
                  <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=papagalo%20bremen&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                  </iframe>
            </div>
      </div>
    </div>
  );
  }

  export default FindUs;
