import React, {useEffect } from 'react';
import {BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
// BsInstagram
import './Michelin_gallerie.css';
import { images } from '../../constants';
import 'lightbox2/dist/css/lightbox.min.css';
import lightbox from 'lightbox2';

const Michelin_gallerie = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 400;
    } else {
      current.scrollLeft += 400;
    }
  };
  useEffect(() => {
    // Add data-lightbox attribute to all images
    document.querySelectorAll('.app__gallery-images_card img').forEach(image => {
      image.setAttribute('data-lightbox', 'gallery');
    });
  
    // Override Lightbox options
    lightbox.option({
      'showImageNumberLabel': false,
      'alwaysShowNavOnTouchDevices': true,
      'maxWidth': 1200, // Set maximum width of the lightbox
      'maxHeight': 1000, // Set maximum height of the lightbox
      'scrollZoom': true, // Enable scrolling within the lightbox
      'wrapAround': true, // Allow wrapping around to the first/last image
      'positionFromTop': 100,
      'resizeDuration':20,
      'fadeDuration':20,
      'imageFadeDuration':20
    });
  
  }, []);
   
  

  return (
    <div className="app__gallery app__bg section__padding" id="Galerie">
      <div className="app__gallery-michelin" >
        <div className="app__gallery-michelin_container" ref={scrollRef} >
          {[
            

            images.Michelin_2023,
            images.Michelin_2022,
            images.Michelin_2021,
            images.Michelin_2020,
            images.Michelin_2019,
            images.Michelin_2018,
            images.Michelin_2017,
            images.Michelin_2016,
            images.Michelin_2015,
            images.Michelin_2014,
            images.Michelin_2013,
            images.Michelin_2012,
            images.Michelin_2011,
            images.Michelin_2010,
 
            ].map((image, index) => (
            <div className="app__gallery-michelin_card flex__center" key={`michelin_image-${index + 1}`}>
                <img src={image} alt="michelin_image"/>
            </div>
          ))}
        </div>
        <div className="app__gallery-michelin_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  );
};

export default Michelin_gallerie;
