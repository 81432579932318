import React from 'react';
import { FiInstagram } from 'react-icons/fi';

import { Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer app__bg section__padding" id="Kontakt">
   
    <Newsletter />

    <div className="app__footer-links">
    <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Kontakt</h1>
        <p className="p__opensans">Ristorante Al Pappagallo – G. Ferrulli </p>
        <p className="p__opensans">Außer der Schleifmühle 73 </p>
        <p className="p__opensans"> 28203 Bremen  </p>
        <p className="p__opensans">al.pappagallo@gmx.de</p>
        <p className="p__opensans">(0421) 32 79 63</p>
  
      </div>

      <div className="app__footer-links_logo">
        
        <h1 className="app__footer-h1">Ristorante al Pappagallo</h1>
        <p className="p__opensans">Sie können uns auch auf Instagram finden.</p>
        {/* <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} /> */}
        <div className="app__footer-links_icons">
        {/* <a href="https://www.facebook.com/YourFacebookPage" target="_blank" rel="noopener noreferrer">
          <FiFacebook />
        </a>
        <a href="https://twitter.com/YourTwitterPage" target="_blank" rel="noopener noreferrer">
          <FiTwitter />
        </a> */}
        <a href="https://www.instagram.com/alpappagallo/" target="_blank" rel="noopener noreferrer">
        <FiInstagram style={{ fontSize: 35 }} />
        </a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Öffnungszeiten</h1>
        <p className="p__opensans">Montag-Freitag:</p>
        <p className="p__opensans">12:00 - 14:00 Uhr</p>
        <p className="p__opensans">18:00 - 22:30 Uhr</p>
        <p className="p__opensans">Samstag:</p>
        <p className="p__opensans">18:00 - 22:30 Uhr</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">Copyright © 2024 Al Pappagallo. All rights reserved.</p>
    </div>

  </div>
);

export default Footer;