import React from 'react';
import { images } from '../../constants';
const SubHeading = ({title,icon}) => {
  
  if(icon == "spoon")
  {
    var img = images.spoon
  } else{
    var img = images.location
  }
  
  return(
  <div style={{ marginBottom: '1rem' }}>
      <p className="p__cormorant">{title}</p>
      <img src={img} alt="spoon" className='img' />
     
  </div>
  );
}



export default SubHeading;
