import React from 'react';

import './AboutUs.css';

import {images} from '../../constants';
const AboutUs = () => (
  <div className="app_aboutus app__bg flex__center section__padding" id="about">
  </div>
);

export default AboutUs;
