import React from "react";
import {
  FindUs,
  Footer,
  Galerie1,
  Galerie2,
  GalerieFood1,
  GalerieFood2,
  Header,
  Weinkarte,
  Speisekarte,
  Michelin,
  Michelin_gallerie,
  Catering,
  Karriere
} from "./container";
import { Navbar } from "./components";
import "./App.css";

const App = () => (
  <div>
    <Navbar />
    <Header />
    <Michelin />
    <Michelin_gallerie />
    <Galerie1 />
    <Galerie2 />
    <GalerieFood1 />
    <GalerieFood2 />
    <Speisekarte/>
    <Weinkarte />
    <Catering />
    <FindUs />
    {/* <Karriere /> */}
    <Footer />
  </div>
);

export default App;
